import { PiNotebookDuotone, PiClockLight, PiCircleDuotone } from 'react-icons/pi'
import dompurify from 'dompurify'
import { useRef, useEffect, useState } from 'react'


const Audio = ({ course, setExercise, exercise, goHome, clearParams, userID, name, jwtToken }) => {

    const sanitizer = dompurify.sanitize;
    const audioRef = useRef(null);
    const [finish, setFinish] = useState(false);
 
 
    useEffect(() => {
        const audioElement = audioRef.current;
    
        const handleEnded = () => {
          setFinish(true);
        };
    
        if (audioElement) {
          audioElement.addEventListener('ended', handleEnded);
    
          return () => {
            audioElement.removeEventListener('ended', handleEnded);
          };
        }

        if (exercise === 1) // log course as finished
        {

          fetch(`/calls/cdata.php?cid=${name}&id=${userID}&auth=${jwtToken}`) 
          .then((response) => response.json())
          .then((data) => {
              logCourse(userID, data);
              })

            const logCourse = (userID, cid) => {
            fetch(`/posts/post-finish.php`, {
              method:'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify([parseInt(userID), parseInt(cid), name]),
          })
          .then(response => response.text())
          .then(response => {
              console.log(response);
          })
         }

        }

      }, [audioRef, exercise, userID, name, jwtToken]);
    
    

    return (
        <>
        { exercise === -2 ?
        <div className='title-screen' style={{backgroundImage: `url(images/${course.Image}.jpg)`, backgroundPostion:'center, center', backgroundSize:'cover', backgroundRepeat:'no-repeat'}} onClick={()=> setExercise(-1) }>
            <h1 className='course-title'>{ course.Title }</h1>
             <h3>Click to start</h3>
         </div>
         : exercise === -1 ?
         <div className='copy-wrap'>
                <div className='copy-stage'>
                    <h4>Welcome!</h4>
                      
                       <div className='icon-row'>
                           <div className='left'>
                             <PiNotebookDuotone size={60} fill={'#413d45'} />
                           </div>
                           <div className='right'>
                             <p>To experience the full benefits of our morning writing courses, simply grab a notebook and pen, find a quiet spot, and let yourself write freely and continuously. Allow a minimum of 30 minutes for each session.</p>
                           </div>
                        </div>

                        <div className='icon-row'>
                           <div className='left'>
                             <PiClockLight size={60} fill={'#413d45'} />
                           </div>
                           <div className='right'>
                           <p>Our audio courses include a warm up exercise, followed by three prompts of five minutes, finishing with a 10-minute exercise.</p>
                           </div>
                        </div>

                        <div className='icon-row'>
                           <div className='left'>
                             <PiCircleDuotone size={60} fill={'#413d45'} />
                           </div>
                           <div className='right'>
                           <p>As the course ends, a yellow circle will appear. Click this so that you can log the course as completed!</p>
                           </div>
                        </div>

                    <div className='circle-start' onClick={()=> setExercise(0)}>Click to Start!</div>
                </div>
            </div>
            : exercise === 0 ?
            
            <div className='copy-wrap'>
                <div className='copy-stage'>
                <h4>{course.Title}</h4>
                <audio ref={audioRef} controls controlsList='nodownload' style={{ width: '100%', marginTop:'20px', marginBottom: '30px' }}>
                    <source src={`audio/${course.Content[exercise].Visual }`} type='audio/mp3' />
                Your browser does not support the audio element.
                </audio>
                <div className='cstyle' dangerouslySetInnerHTML={{__html: sanitizer(course.Content[exercise].Intro)}} />   
                </div>

                { finish &&
                <div className='circle-start circle-clock' onClick={()=> setExercise(1)}>Click to finish</div>
                }

            </div>

            :
            <div className='copy-wrap orange-back'>
                <div className='copy-stage'>
                    <h4>Final thought</h4>
                    <div className='cstyle' dangerouslySetInnerHTML={{__html: sanitizer(course.Content[5].Task)}} />
                    <div className='circle-start b-right' onClick={()=> {goHome(0); clearParams(); }}>Back home</div>
                 </div>
           </div>


                
        }
        </>
    )
}

export default Audio