import Search from './Search'
import { MdSearch, MdClose,  MdLogout, MdAccountCircle, MdCancel, MdMenu } from 'react-icons/md'
import { useState } from 'react'


const Header = ({ course, setCourse, onwriting, setOnwriting, setAccount, setLogged, userID, stack, setStack }) => {

    const [search, setSearch] = useState(false);
    const [menu, setMenu] = useState(false);


    const clearParams = () => {
        const currentURL = new URL(window.location.href);
        const params = new URLSearchParams('');
        currentURL.search = params.toString();
        window.history.pushState(null, null, currentURL.toString());
      };

      const scrollToSection = () => {
        setTimeout(()=> {
            
            const section = document.getElementById('cancel');
            section.scrollIntoView({ behavior: 'smooth' });

              }, 1000)
      }

      const clearJWT = () => {
        localStorage.removeItem('wsjwt');
        setLogged(false);
      }


    return (
        <>
            <header>
                <div className='logo' onClick={()=> {setCourse(0); clearParams(); setOnwriting(false); setAccount(false); setStack(false) }}></div>
                { (course === 0 || course === -1) &&
                <div className='navs'>
                    <ul className='main'>
                        <li className={course === 0 ? 'selected main' : 'main'} onClick={()=> {setCourse(0); clearParams(); setOnwriting(false); setAccount(false); setStack(false) }}>Courses</li>

                        <li className={stack ? 'selected main' : 'main'} onClick={()=>{ setStack(true); setOnwriting(false); setAccount(false); setCourse(-1) }}>My Stack</li>

                        <li className={onwriting ? 'selected main' : 'main'} onClick={()=>{ setOnwriting(true); setStack(false); setAccount(false); setCourse(-1)}}>On Writing</li>
                        
                        <li className='main more' onClick={()=>setMenu(true)}><MdMenu size={30} fill={'#413d45'} style={{'margin':'-5px 0 0 5px'}}  /></li>

                    </ul>
                    { menu &&
                    <ul className='account'>
                         <MdClose size={40} className='t-right' fill={'#413d45'} onClick={()=> setMenu(false) } />
                     <li onClick={()=>{ setAccount(true); setOnwriting(false); setMenu(false); setStack(false); setCourse(-1) }}>
                            <MdAccountCircle size={40} fill={'#413d45'} style={{'margin':'0 10px 0 0'}} />Your account
                        </li>

                        <li onClick={()=>{setAccount(true); setOnwriting(false); setMenu(false); setStack(false); setCourse(-1); scrollToSection() }}>
                        <MdCancel size={40} fill={'#f15a41'} style={{'margin':'0 10px 0 0'}} />Cancel subscription
                        </li>


                        <li onClick={()=>clearJWT()}>
                        <MdLogout size={30} fill={'#413d45'} style={{'margin':'0 10px 0 5px'}} />Logout
                        </li>
                    </ul>
                    }
                </div>
                }
                    { (course === 0 || course === -1) && 
                    <>
                        { search ?
                        
                        <MdClose size={40} className='search-icon' fill={'#413d45'} onClick={()=> setSearch(false) } />

                        :
                        <MdSearch size={40} className='search-icon' fill={'#413d45'} onClick={()=> setSearch(true)} />

                        }
                    </>
                   }
            </header>
            { (course === 0 || course === -1) &&
                <>
                { search &&
                <Search  setCourse={ setCourse } search={ search } userID={ userID } />
                }
                </>
            }
        </>
    )
}

export default Header