const HeaderBlank = () => {


    return (
        
            <header>
                <div className='logo-login'></div>
            </header>
    )
}

export default HeaderBlank