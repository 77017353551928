import { MdAddCard } from 'react-icons/md'
import { useState } from 'react'


const UpdateCard = ({ id }) => {

    const [cancel, setCancel] = useState(false);

 

    return (
        <>
        <div className='update-wrap' onClick={()=>{  setCancel(!cancel); }}>
            <MdAddCard size={40} fill={'#413d45'} />
            <p>Update your payment details.</p>
         </div>
           {
            cancel &&
         <div className='cancel-msg'>
           <p className='coda'>Write &amp; Shine partners with Stripe for subscriptions. Click the button below and you'll be taken to our Stripe portal to update your details.</p>
           <a href='https://billing.stripe.com/p/login/28o7uYa5P4LB5KU7ss'><button className='cta'>Go to portal</button></a>
         </div>
         }
         </>
    )
}

export default UpdateCard