import dompurify from 'dompurify'


const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    
    const lastSpaceIndex = text.lastIndexOf(' ', maxLength);
    
    if (lastSpaceIndex !== -1) {
      return `${text.substring(0, lastSpaceIndex)}...`;
    }
    
    return `${text.substring(0, maxLength)}...`;
  };

  
  const Truncate = ({ text, maxLength }) => {
    const sanitizer = dompurify.sanitize;
    const truncatedText = truncateText(text, maxLength);
  
    return (
       <p className='lede' dangerouslySetInnerHTML={{__html: sanitizer(truncatedText)}} />   
    );
  };


  
  export default Truncate;