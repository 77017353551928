import { MdClose } from 'react-icons/md'


const Announce = ({ setAnnouncement, userID }) => {


    const closeAnnounce = () => {

                // close announcement
      fetch(`/calls/announce.php`, {
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify([parseInt(userID), 'close']),
         })
        .then(response => response.text())
        .then(response => {
            console.log(response);
            setAnnouncement(false);
        })

    }
 

    return (
        <div className='announce-wrap'>
            <div className='close' onClick={()=>closeAnnounce()}><MdClose size={50} fill={'#413d45'} /></div>
            <h2>Introducing Stacks</h2>
            <p>You can now save the courses you want to do, or curate your favourites, by adding them to your stack. Look out for the three dots in the corner of the course cards and click the "Stack" button to save a course. Visit the "My Stack" page above to view your list. Enjoy!</p>
        </div>
    )
}

export default Announce