import { MdOutlineSpeakerNotes } from 'react-icons/md'


const Contact = () => {


 

    return (
      <>
        <div className='update-wrap'>
            <MdOutlineSpeakerNotes size={40} fill={'#413d45'} />
            <a href='https://write-and-shine.com/contact/' target='_blank' rel='noreferrer'>
              <p>Contact us.</p></a>
         </div>
   
      </>
    )
}

export default Contact