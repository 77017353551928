import { useState } from 'react'

const Login = ({ setLogged, setUserID }) => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [address, setAddress] = useState('');
    const [logError, setLogError] = useState(false);

  
    const handleSubmit = async (e) => {
      e.preventDefault();

      if (address)
      {
        return;
      }

      try {
        const response = await fetch('/auth/login.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, password }),
        });
    
        if (!response.ok) {
          throw new Error();
        }
    
        const { userID, JWT } = await response.json();

    
        // Store JWT in local storage
        localStorage.setItem('wsjwt', JWT);
        setLogged(true);
        setUserID(userID);
    
    
        // You can also redirect to a new page or update the state to reflect the authenticated status
      } catch (error) {
        // Handle login failure
        setLogError(true);
        setEmail('');
        setPassword('');
      }
  
 
    };
  
    return (
      <div className='login-wrap'>
        <div className='left'>
          <h2>Welcome to our online library</h2>
          <br />
          <p>Write &amp; Shine's online library helps you start your day with a burst of creativity! With over 175 courses (and counting!) for you to explore, each takes a theme and provides you with thoughtful timed exercises to inspire you and help you commit to a writing habit.</p>

          <p>Our courses take inspiration from the seasons, the natural world and taking a closer look at our everyday lives. We also have courses focusing on some of the writers we love (including Shirley Jackson, Jean Rhys and James Baldwin), and guest courses penned by Write &amp; Shine's guest tutors including Rowan Hisayo Buchanan, Emily Berry and Vanessa Onwuemezi.</p>
          <p>Subscribe today with a 7-day free trial. Cancel anytime.</p>
          <a href='https://buy.stripe.com/3cs16t37aah22RicMN'>
           <div className='login-subscribe'>Subscribe!</div>
          </a>

        </div>
       
        <div className='right'>
          <a href='https://write-and-shine.online/pw-reset.html'><div className='login-forgot'>Forgot password?</div></a>
          { logError &&
          <div className='login-error'>Username / password incorrect.</div>
          }
          <form onSubmit={handleSubmit}>
              <input type='email' placeholder='Your email' className='logging' value={email} onChange={(e) => setEmail(e.target.value)} required />
      
            <br />
      
              <input type='password' placeholder='Your password' className='logging' value={password} onChange={(e) => setPassword(e.target.value)} required />
      
            <br />

            <input type='address' placeholder='Your password' className='logging address' value={address} onChange={(e) => setAddress(e.target.value)} />
            
            <br />
      
            <button type='submit' className='login'>Login</button>
          </form>
        </div>
       
      </div>
    );
  }


export default Login