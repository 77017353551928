import { useState, useEffect } from 'react'
import { MdCheck } from 'react-icons/md';


const Lottery = ({ id }) => {
    
    const promo = 'Autumn season';
    const [animate, setAnimate] = useState(false);
    const [buttonState, setButtonState] = useState({
        clicked: false,
        entered: false
      });
    
      const today = new Date();
      const minutes = today.getSeconds();

    useEffect(()=> {

        fetch(`/calls/lottery.php?x=${minutes}`, {
            method:'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({'ID': id, 'Type':'check'}),
        })
        .then(response => response.json())
        .then(response => {
            if (response)
            {
             setButtonState({
                clicked: false,
                entered: true
              })
            }
        })
        .catch(error => {
            console.log(error);
        });

       setTimeout(()=> {
          setAnimate(true)
            }, 1000)


      },[id, buttonState.entered]);

  
      const handleClick = () => {
        setButtonState({
          clicked: true,
          entered: false
        });
        setTimeout(() => setButtonState({
          clicked: false,
          entered: true
        }), 1000); 


        fetch('/calls/lottery.php', {
            method:'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({'ID': id, 'Type':'post', 'Promo': promo }),
        })
        .then(response => response.text())
        .then(response => {
            if (response)
             console.log(response);
        })
        .catch(error => {
            console.log(error);
        });

      };


      const popRow = 
      {
       opacity: animate ? 1 : 0, // Fade in effect
       transform: animate ? 'translateY(0px)' : 'translateY(50px)', // Move up effect
       transition: 'opacity 1s ease, transform 1s ease', // Animation duration and easing
      };



    return (
        <section className='featured' style={popRow}>
            <div className='lottery-wrap'>
                
                <h2 className='tag'>Spring Salon: prize draw!</h2>
              
                <div className='left'>
                    <div className='print'></div>
                </div>
               
                <div className='right'>
                    <p>We're celebrating the launch of our <a href='https://booking.write-and-shine.com/' target='_blank' rel='noreferrer' className='link'>autumn season</a>! We always work with artists and illustrators we love to create a new work that captures the inspiration behind our programmes.</p>
                    <p>Illustrator Rose Wong has created our latest artwork. Fancy a limited edition print? You can enter our prize draw for a chance to win Rose's wonderful work.</p>
                  
                    <button className='full' style={{backgroundColor: buttonState.entered ? '#ff9595' : 'auto', color: buttonState.entered ? '#ffffff' : '#413d45'}} onClick={buttonState.entered ? null : handleClick}>
                    {buttonState.entered ? 'You\'re Entered!' : buttonState.clicked ? <MdCheck size='22' fill='#413d45' /> : 'Enter draw!'}
                    </button>

                </div>
           
            </div>
            
        </section>
    )
}




export default Lottery

