
const Typewriters = ({ exercise, setExercise, setStage}) => {

    const levels = [1, 2, 3, 4];

    return (
         
    <div className='typewriter-rack'>
            {
                levels.map((level, key)=> (
                    <div className='typewriter' onClick={() => {setExercise(level); setStage(1)}} key={key}>
                    <p className='p-type'>{level}</p>
                    { exercise > level ? 
                    <img src='icons/typewriter-done.svg' className='tw-icon' alt='typewriter icon' />
                    :
                    <img src='icons/typewriter-todo.svg' className='tw-icon' alt='typewriter icon' />
                    }
                    </div>
                ))
            }

    </div>
      
    )
}

export default Typewriters