import {useEffect, useState} from 'react'
import { MdEdit, MdCheckCircle, MdError, MdClose, MdBackspace } from 'react-icons/md'
import Progress from '../components/Progress'
import Cancel from '../components/Cancel'
import UpdateCard from '../components/UpdateCard'
import Contact from '../components/Contact'


const Account = ({ userID }) => {

    const id = userID;
    const [data, setData] = useState([]);
    const [update, setUpdate] = useState(0);

    const [fname, setFname] = useState('');
    const [sname, setSname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [checkPass, setCheckPass] = useState('');


    const [editName, setEditName] = useState(false);
    const [editEmail, setEditEmail] = useState(false);
    const [changePass, setChangePass] = useState(false);
    const [validationError, setValidationError] = useState('');


    const [confirm, setConfirm] = useState();

    


    useEffect(()=> {

        fetch('/calls/account.php', {
            method:'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(id),
        })
        .then(response => response.json())
        .then(response => {

            response.forEach(a=>{ 
                a.Sub_period_end = new Date(a.Sub_period_end * 1000).toLocaleDateString();
            })
            setData(response);
        })
        .catch(error => {
            console.log(error);
        });

    },[id, update])


    const pop = 
    {
      opacity: data.length > 0 ? 1 : 0, // Fade in effect
      transform: data.length > 0 ? 'translateY(0px)' : 'translateY(50px)', // Move up effect
      transition: 'opacity 1s ease, transform 1s ease', // Animation duration and easing
    };

    

    // updates states on input
  const handleInputChange = (event, type) => {

            if (type === 'firstname')
            {
            setFname(event.target.value)
            }

            if (type === 'secondname')
            {
            setSname(event.target.value)
            }

            if (type === 'email')
            {
            setEmail(event.target.value)
            }

            if (type === 'password')
            {
            setPassword(event.target.value)
            }

            if (type === 'checkpassword')
            {
            setCheckPass(event.target.value)
            }
        
        };

            // validating inputs and taking to confirm popup
        const submitChange = (type) => {


            if (type === 'name')
            {
                if (fname.length < 1 || sname.length < 1)  {
                    setValidationError('Your name must contain one letter.');
                  } 
                  else {
                    setConfirm('name');
                    setValidationError();
                  }
            }

            if (type === 'email')
            {
                if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
                    setValidationError('Is the format of your email right?');
                  } 
                  else {
                    setConfirm('email');
                    setValidationError();
                  }
            }



            if (type === 'password')
            {

                if (password.length < 5)  {
                    setValidationError('Your password needs at least five characters.');
                  } 
                  else {
                    setConfirm('password');
                    setValidationError();
                  }

            }

        }

        // update to the db
        const updateProf = (field) => {


            fetch(`/calls/update-account.php?f=${field}`, {
                method:'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify([id, fname, sname, email, password]),
            })
            .then(response => response.text())
            .then(response => {
                console.log(response);
                setUpdate(prevCount => prevCount + 1);
            })
            .catch(error => {
                console.log(error);
            });


        }

        // to reset all fields and return to db saved state
        const reset = () => {

            setEditName(false); setEditEmail(false); setChangePass(false); setFname(''); setSname(''); setEmail(''); setPassword(''); setValidationError('');

        }


    return (
        <>
        
         { confirm && // the confirm popup
            <div className='account-back'>
            <div className='confirm-box'>
                <MdClose size={30} className='t-right' fill={'#413d45'} onClick={()=>{ setConfirm(); reset() }} />
                { confirm === 'name' ?
                <>
                    <p className='bold'>Update name to:</p>
                    <p>{fname + ' ' + sname}</p>
                    <button className='cta' onClick={()=>{updateProf(confirm); setConfirm(); setEditName(false); setEditEmail(false); setChangePass(false); }}>Confirm</button>
                </>
                :  confirm === 'email' ?
                <>
                   <p className='bold'>Update email to:</p>
                   <p>{email}</p>
                   <button className='cta' onClick={()=>{updateProf(confirm); setConfirm(); setEditName(false); setEditEmail(false); setChangePass(false); }}>Confirm</button>
                </>
                :
                <>
                   <>
                   <p className='bold'>Update your password?</p>
                   <input type='text' value={checkPass} onChange={(event) => handleInputChange(event, 'checkpassword')} placeholder="Retype new password to confirm" /> 
                   { checkPass === password ?
                      <button className='cta' onClick={()=>{updateProf(confirm); setConfirm(); setEditName(false); setEditEmail(false); setChangePass(false); }}>Confirm</button>
                        :
                      <button className='inactive'>No match</button>
                    }
                   </>    
                </>
                }
               
            </div>
        </div>
        }




        {data &&
        <div className='account-wrap' style={pop}>
            <h2>Account details</h2>
            <div className='lede-wrap'>
            <p className='lede'>This is where you can change your details and password, see your progress charted and cancel your subscription.</p>
            </div>

            <h5>Your details</h5>

            { data.map((d,i)=>( // display what's on the DB

            <div className='details-wrap' key={i}>
                {validationError && // input validation message
                <div className='cancel-wrap ocean-back'>
                <MdError size={40} fill={'#fff'} />
                <p className='white'>{ validationError }</p>
                </div>
                }
                <div className='row'>
                    <div className='left'>
                        <p>Name</p>
                    </div>
                    <div className='right'>
                        {
                        editName ? // editable version
                        <>
                         <input type='text' value={fname} onChange={(event) => handleInputChange(event, 'firstname')} placeholder="Set first name" /> 

                         <input type='text' value={sname} onChange={(event) => handleInputChange(event, 'secondname')} placeholder="Set second name" /> 

                          <div>
                            <MdBackspace size={30} fill={'#ff9595'} onClick={()=>{ reset() }} />
                            <MdCheckCircle size={30} fill={'#0c6ff9'} onClick={()=>{ submitChange('name') }} />
                          </div>
                        </>
                        :
                        <>
                        <p>{d.First_name + ' ' + d.Second_name}</p>
                        <MdEdit size={20} fill={'#413d45'} onClick={()=>{ setEditName(true) }} />
                        </>
                        }
                    </div>
                </div>
                <div className='row'>
                    <div className='left'>
                        <p>Email</p>
                    </div>
                    <div className='right'>
                        { editEmail ? 
                        <>
                         <input type='text' value={email} onChange={(event) => handleInputChange(event, 'email')} placeholder="Set email" /> 

                         <div>
                            <MdBackspace size={30} fill={'#ff9595'} onClick={()=>{ reset() }} />
                            <MdCheckCircle size={30} fill={'#0c6ff9'} onClick={()=>{ submitChange('email') }} />
                        </div>

                        </>
                        :
                        <>
                        <p>{d.Email}</p>
                        <MdEdit size={20} fill={'#413d45'} onClick={()=>{ setEditEmail(true) }} />
                        </>
                    }
                    </div>
                </div>
                <div className='row'>
                    <div className='left'>
                        <p>Password</p>
                    </div>
                    <div className='right'>
                    { changePass ? 
                        <>
                         <input type='text' value={password} onChange={(event) => handleInputChange(event, 'password')} placeholder="Set password" /> 

                         <div>
                            <MdBackspace size={30} fill={'#ff9595'} onClick={()=>{ reset() }} />
                            <MdCheckCircle size={30} fill={'#0c6ff9'} onClick={()=>{ submitChange('password') }} />
                         </div>

                        </>
                        :
                        <>
                        <p>[encrypted]</p>
                        <MdEdit size={20} fill={'#413d45'} onClick={()=>{ setChangePass(true) }} />
                        </>
                    }
                    </div>
                </div>
                <div className='row'>
                    <div className='left'>
                        <p>Renewal date</p>
                    </div>
                    <div className='right'>
                        <p>{d.Sub_period_end}</p>
                    </div>
                </div>
            </div>
                            ))
                        }

        
        <Cancel id={ id } />
        
        <br />

        <UpdateCard id={ id } />

        <br />

        <Contact />

        

        <h5>Your progress</h5>

         <Progress id={ id } />

        <div className='fleuron'></div>
        </div>
        }



        </>
    )
}

export default Account