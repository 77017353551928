import { MdOutlineEditCalendar, MdCancel, MdOutlineSpeakerNotes } from 'react-icons/md';



const Footer = ({ setAccount, setOnwriting }) => {

    const scrollToSection = () => {
        setTimeout(()=> {
            
            const section = document.getElementById('cancel');
            section.scrollIntoView({ behavior: 'smooth' });

              }, 1000)
      }

    return (
        <footer>
            <div className='left'>
                <ul>
                <a href='https://write-and-shine.com/contact/'><li><MdOutlineSpeakerNotes size={30} fill={'#413d45'} style={{'margin':'0px 10px -8px 0px'}} />Contact</li></a>
                <li onClick={()=>{setAccount(true); setOnwriting(false); scrollToSection() }}><MdCancel size={30} fill={'#413d45'} style={{'margin':'0px 10px -8px 0px'}} />Cancel</li>
                <a href='https://booking.write-and-shine.com' target='_blank' rel='noreferrer'><li><MdOutlineEditCalendar size={30} fill={'#413d45'} style={{'margin':'0px 10px -8px 0px'}} />
 Workshops</li></a>
                </ul>
            </div>
            <div className='centre'></div>
            <div className='right'>
                <p className='sub'>Each season we commission artists and illustrators we love to create work that captures seasonal changes and the themes of our programme. </p>
                <p className='sub'>The artwork for our autumn season is by the wonderful Rose Wong!</p>
                <p className='sub'>(Our plus members also receive them as special giclée prints!)</p>
            </div>
        </footer>
    )

}

export default Footer