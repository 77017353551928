import React, { useState, useEffect, useReducer, useRef } from 'react';
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";

const BookList = ({ RLdata }) => {
    const [animate, setAnimate] = useState(false);
    const [run, setRun] = useState(0);
    const swipeRef = useRef(null);

    // Reducer function
    const reducer = (state, action) => {
        switch (action.type) {
            case 'SET_BOOK':
                return { ...state, book: action.payload };
            case 'SET_SHUFFLE':
                return { ...state, shuffle: action.payload };
            default:
                return state;
        }
    };

    // Initial state
    const initialState = {
        book: null,
        shuffle: []
    };

    // useReducer hook
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        setTimeout(() => {
            setAnimate(true);
        }, 500);

        const shuffledArray = shuffleArray(RLdata);
        dispatch({ type: 'SET_SHUFFLE', payload: shuffledArray });
        dispatch({ type: 'SET_BOOK', payload: shuffledArray[0] });
    }, [RLdata]);

    useEffect(() => {
        setAnimate(false);
        const timeout = setTimeout(() => setAnimate(true), 0);
        return () => clearTimeout(timeout);
    }, [run]);

    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    const handleTouchStart = (e) => {
        swipeRef.current = { xStart: e.touches[0].clientX };
    };

    const handleTouchMove = (e) => {
        swipeRef.current.xEnd = e.touches[0].clientX;
    };

    const handleTouchEnd = () => {
        const { xStart, xEnd } = swipeRef.current;
        if (xStart && xEnd) {
            const dx = xEnd - xStart;
            if (Math.abs(dx) > 50) { // Adjust the threshold for swipe sensitivity
                if (dx > 0 && run > 0) {
                    // Swipe right
                    runBook(run - 1);
                } else if (dx < 0 && run < RLdata.length - 1) {
                    // Swipe left
                    runBook(run + 1);
                }
            }
        }
        swipeRef.current = {};
    };

    const runBook = (i) => {
        dispatch({ type: 'SET_BOOK', payload: state.shuffle[i] });
        setRun(i);
    };

    const popRow = {
        opacity: animate ? 1 : 0,
        transform: animate ? 'translateY(0px)' : 'translateY(50px)',
        transition: 'opacity 0.5s ease, transform 0.5s ease' // Adjust the duration and easing as needed
    };

    return (
        <section className='featured' style={popRow}>
            <div
                className='featured-book'
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                <h2 className='tag book'>Short story collections we love!</h2>

                {run < RLdata.length - 1 ?
                    <div className='b-right'>
                        <p>{(run + 1) + ' / ' + (RLdata.length)}</p>
                        <MdArrowForwardIos size={40} fill={'#51516b'} style={{ cursor: 'pointer' }} onClick={() => runBook(run + 1)} />
                    </div>
                    :
                    <div className='b-right'>
                        <p>{(run + 1) + ' / ' + (RLdata.length)}</p>
                        <MdArrowForwardIos size={40} fill={'#ececec'} style={{ cursor: 'pointer' }} />
                    </div>
                }

                {run > 0 &&
                    <MdArrowBackIos className='b-left' size={40} fill={'#51516b'} style={{ cursor: 'pointer' }} onClick={() => runBook(run - 1)} />
                }

                {state.book &&
                    <>
                        <div className='left black-font'>
                            <img src={`books/${state.book.Image}`} alt={`${state.book.Title} book cover`} className='book-shadow' />
                        </div>

                        <div className='right book'>
                            <h6>{state.book.Title}</h6>
                            <p>{state.book.Author}</p>
                            <br />
                            <p><i>{state.book.Description}</i></p>
                            <br /><br />
                            <a href={`${state.book.Link}`} target='_blank' rel='noreferrer'><button className='cta'>Buy here</button></a>
                        </div>
                    </>
                }

            </div>
        </section>
    )
}

export default BookList;
