 // eslint-disable-next-line
import { useState, useRef, userID } from 'react'
import RowSearch from './RowSearch'

const SearchComponent = ({  setCourse, search, userID }) => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const searchTimeoutRef = useRef(null);
  const [animate, setAnimate] = useState(false);
  const jwtToken = localStorage.getItem('wsjwt');


  const handleSearch = () => {
    try {
      fetch(`/calls/cdata.php?id=${userID}&auth=${jwtToken}`)
        .then(response => response.json())
        .then(data => {
            const filteredResults = data.filter(item =>
              item.Title.toLowerCase().includes(query.toLowerCase()) ||
              item.Guest.toLowerCase().includes(query.toLowerCase()) ||
              (item.Theme && item.Theme.some(theme =>
                theme.toLowerCase().includes(query.toLowerCase())
              ))
            );
            setResults(filteredResults);
            setAnimate(true);
          })
        .catch(error => console.error('Error fetching data:', error));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleChange = event => {
    const value = event.target.value;
    setQuery(value);

    // Clear previous timeout
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    // Set a new timeout
    searchTimeoutRef.current = setTimeout(() => {
      if (value.trim() !== '') {
        handleSearch();
        setAnimate(false);
      } else {
        setResults([]);
      }
    }, 300);

  }

  return (
    <>
    <div className='search-wrap'>
      { search ?
      <input
        type='text' autoFocus
        value={query}
        onChange={handleChange}
        placeholder='Search courses' className='search-input'
      />
      :
      <input
        type='text'
        value={query}
        onChange={handleChange}
        placeholder='Search courses' className='search-input'
      />
     }

    {results.length === 0 && query.trim() !== '' && (
            <p>No results found</p>
          )}

    </div>

          <RowSearch results={results} animate={animate} id={userID} setCourse={setCourse} jwtToken={jwtToken}  />
    </>
  );
};

export default SearchComponent;
