import { useState, useEffect } from 'react'
import Row from '../components/Row'

const Stack = ({ userID, jwtToken, setCourse }) => {


    const [empty, setEmpty] = useState();
    const [title, setTitle] = useState('Getting your stack...');

    useEffect(()=>{

        setTimeout(()=> {
              
        if (empty === true)
            {
                setTitle('Your stack is empty. Why not add one of these courses?')
            }
        if (empty === false)
            {
                setTitle('Your saved courses')
            }

        }, 1000)

    }, [empty])

    


    return (
        <div className='wrapper'>
            <div className='special-badge'>Welcome to your Stack! Curate your courses to-do and favourites.</div>
            <br /><br /><br /><br />

            <h2 className='section'>{title}</h2>

            <Row type='stack' value={1} hide={null} id={userID} setCourse={setCourse} jwtToken={jwtToken} setEmpty={setEmpty} empty={empty} />

        
  
        </div>
    )
}

export default Stack