import { useState, useEffect } from 'react'
import OWdata from '../api/OnWriting.js?v=4'
import SSdata from '../api/ReadingListSS?v=0'
import Truncate from '../components/Truncate'
import Article from '../components/Article'
import BookList from '../components/BookList'

const OnWriting = ({article, setArticle, userID}) => {

    const [page, setPage] =  useState(false);
    const [articleData, setArticleData] = useState(); 
    const [animate, setAnimate] = useState(false);


    useEffect( ()=> {
        
          setTimeout(()=> {
            setAnimate(true)
          }, 500)

            }, []);


      useEffect( ()=> {
        
     if (article)
         {
       let select = OWdata.filter(a=>(a.ID === parseInt(article)));
       openCard(select[0]);
       }
         // eslint-disable-next-line
     }, [article]);
            
    
    useEffect( ()=> {
      window.scrollTo(0, 0);
    },[page])

     const pop = 
        {
          opacity: animate ? 1 : 0, // Fade in effect
          transform: animate ? 'translateY(0px)' : 'translateY(50px)', // Move up effect
          transition: 'opacity 1s ease, transform 1s ease', // Animation duration and easing
        };

 
        const addParamToURL = (paramName, paramValue) => {
          const currentURL = new URL(window.location.href);
          currentURL.searchParams.set(paramName, paramValue);
          const newURL = currentURL.toString();
        
          window.history.pushState(null, null, newURL);
        };
        
      
      const openCard = (data) => {
          setAnimate(false)
          setPage(true);
          setArticleData(data);

          setTimeout(()=> {
            setAnimate(true)
          }, 500)

          addParamToURL('a', data.ID);

          clickedArticle(userID, data.Title);

      };

      const clickedArticle = async (userID, artTitle) => {
        try {
            const response = await fetch(`/posts/article-start.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify([parseInt(userID), artTitle]),
            });
            const result = await response.text();
            console.log(result);
        } catch (error) {
            console.error('Error logging article:', error);
        }
    };


    return (
          <>        
          { page ? 
          <Article pop={pop} d={articleData} setPage={setPage} setArticle={setArticle} />
          :
          <>
         <div className='ow-card-wrap'>
          { 
            OWdata.sort((a, b) => b.ID - a.ID).map((d,i)=>
            <div className='ow-card' key={i} style={pop}>
              <h2>{d.Title}</h2>
             
              <div className='ow-card-photo' style={{backgroundImage: `url(images/${d.Image})`, backgroundPostion:'center, center', backgroundSize:'cover', backgroundRepeat:'no-repeat'}}></div>

              <Truncate text={ d.Lede } maxLength={150} />
              <button onClick={()=> openCard(d)}>Read more</button>
            </div>
            )
          }
        </div>
        <BookList RLdata={ SSdata } />
        </>
        }
      </>

    )
}

export default OnWriting