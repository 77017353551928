import { useState, useEffect } from 'react'
import dompurify from 'dompurify'
import Row from '../components/Row'
import Featured from '../components/Featured'
import Random from '../components/Random'
import Lottery from '../components/Lottery'
import Announce from '../components/Announce'


const Home = ({ setCourse, userID }) => {

    const sanitizer = dompurify.sanitize;
    const [loaded, setLoaded] = useState(false);
    const jwtToken = localStorage.getItem('wsjwt');

    const [hero, setHero] = useState();
    const [animateH, setAnimateH] = useState(false);
    
    const [isToggled, setToggled] = useState(false);
    const [announcement, setAnnouncement] = useState(false);

    const hideDone = () => {
        setToggled(!isToggled);
      };



    const collection = 
    [
    {'Title':'Eat up!', 'Theme':'Food'},
    {'Title':'Tweet of the day', 'Theme':'Birds'},
    {'Title':'Get happy!', 'Theme':'Happy'},
    {'Title':'New rituals', 'Theme':'Ritual'},
    {'Title':'Mind and body', 'Theme':'Body'},
    {'Title':'Inspired by art', 'Theme':'Art'},
    {'Title':'Streams of light', 'Theme':'Light'},
    {'Title':'The flaneuse!', 'Theme': 'Urban'},
    {'Title':'Take a trip!', 'Theme':'Travel'},
    {'Title':'Your colour palette', 'Theme':'Colour'},
    {'Title':'Explore the natural world', 'Theme':'Nature'},
    {'Title':'Close to home', 'Theme':'Home'}
    ];

    const [topRand, setTopRand] = useState();


    const [scrollTop, setScrollTop] = useState(0);

    useEffect(() => {
        
        const handleScroll = event => {
 
          if (window.scrollY > scrollTop)
          {
            setScrollTop(window.scrollY);
          }
        };
    
        window.addEventListener('scroll', handleScroll);

    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };


      }, [scrollTop]);




    useEffect( ()=> {
 
      const today = new Date();
      const minutes = today.getSeconds();
        
        fetch(`/calls/cdata.php?h=1&id=${userID}&auth=${jwtToken}&x=${minutes}`, {cache: 'no-store'}) // hero
        .then((response) => response.json())
        .then((data) => {
          setHero(data);
          setLoaded(true);
          

          setTimeout(()=> {
            setAnimateH(true)
          }, 500)

             })

             const rand = Math.floor(Math.random() * collection.length);

             setTopRand(rand);
            
            }, [jwtToken, userID]);

    
    useEffect(()=>{

        // check announcement
      fetch(`/calls/announce.php`, {
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify([parseInt(userID), 'check']),
         })
        .then(response => response.text())
        .then(response => {
           if (parseInt(response) === 0)
            {
              setAnnouncement(true);
            }
        })

      }, [announcement, userID])


    const popHero = 
    {
     opacity: animateH ? 1 : 0, // Fade in effect
     transform: animateH ? 'translateY(0px)' : 'translateY(50px)', // Move up effect
    transition: 'opacity 1s ease, transform 1s ease', // Animation duration and easing
    };
        

    return (
        <div className='wrapper'>
            { loaded && 
            <>
            {announcement &&
            <Announce setAnnouncement={setAnnouncement} userID={userID} />
            }
            { hero &&
              <section className='hero'>
                <div className='hero-wrap' style={popHero} onClick={()=>setCourse( hero[0].Title )}>
                    <h2 className='tag'>Recently added</h2>
                    <div className='top' style={{backgroundImage: `url(images/${hero[0].Image}.jpg)`, backgroundPostion:'center, center', backgroundSize:'cover', backgroundRepeat:'no-repeat'}}>
                        <h1 className='hero'>{hero[0].Title}</h1>
                    </div>
                    <div className='bottom'>
                        <div className='pstyle' dangerouslySetInnerHTML={{__html: sanitizer(hero[0].Content[0].Intro)}} />   
                    </div>
                </div>

              </section>
                 }

          {scrollTop >= 0 &&
         
            <>
            <div className={`slider-container ${isToggled ? 'active' : ''}`}>
               <label className="slider">
                 <input type='checkbox' checked={isToggled} onChange={hideDone} />
                 <span className='slider-toggle round'></span>
               </label>
            <div className='status'>{isToggled ? 'Completed courses hidden!' : 'Hide your completed courses'}</div>
           </div>     
            
            
            <h2 className='section'>{collection[topRand].Title}</h2>
              <Row type='t' value={collection[topRand].Theme} hide={isToggled} id={userID} setCourse={setCourse} jwtToken={jwtToken} />
            </>
          }

           <h2 className='section'>Archetypes collection</h2>
             { scrollTop >= 50 &&
            <Row type='t' value='Archetypes' hide={isToggled} id={userID} setCourse={setCourse} jwtToken={jwtToken} />

             }

          { scrollTop >= 80 &&
          <>
          <Lottery id={userID} />  
          </>
          }

          <h2 className='section'>Hone your craft</h2>
             { scrollTop >= 110 &&
            <Row type='t' value='Craft' hide={isToggled} id={userID} setCourse={setCourse} jwtToken={jwtToken} />

             }
       
            <h2 className='section'>Audio courses</h2>
            { scrollTop >= 140 &&
            <Row type='t' value='Audio' hide={isToggled} id={userID} setCourse={setCourse} jwtToken={jwtToken} />
            }

            { scrollTop >= 180 &&
            <Random setCourse={setCourse} id={userID} jwtToken={jwtToken} />
             }

            <h2 className='section'>From our guest tutors</h2>
             { scrollTop >= 220 &&
            <Row type='g' value='1' hide={isToggled} id={userID} setCourse={setCourse} jwtToken={jwtToken} />
             }

            <h2 className='section'>Our writers series</h2>
             { scrollTop >= 250 &&
            <Row type='t' value='Writers' hide={isToggled} id={userID} setCourse={setCourse} jwtToken={jwtToken} />
             }

             { scrollTop >= 300 &&
            <Featured value='Maps' id={userID} setCourse={setCourse} jwtToken={jwtToken} />
             }

          <h2 className='section'>For you</h2>
             { scrollTop >= 330 &&
            <Row type='u' value={1} hide={isToggled} id={userID} setCourse={setCourse} jwtToken={jwtToken} />
             }
            
            </>
            }
        </div>
    )
}

export default Home