import { useState, useEffect } from 'react'
import dompurify from 'dompurify'
import Counter from '../components/Counter'
import Typewriters from '../components/Typewriters'
import Audio from '../components/Audio'
import { PiNotebookDuotone, PiClockLight, PiCircleDuotone, PiCubeDuotone } from 'react-icons/pi'


const Course = ({ name, goHome, userID }) => {

    const sanitizer = dompurify.sanitize;
    const jwtToken = localStorage.getItem('wsjwt');
    const [course, setCourse] = useState();
    const [audio, setAudio] = useState(false);
    const [animate, setAnimate] = useState(false);

    const [exercise, setExercise] = useState(-2);
    const [stage, setStage] = useState(1);
    const [tip, setTip] = useState(false);


    const addParamToURL = (paramName, paramValue) => {
        const currentURL = new URL(window.location.href);
        currentURL.searchParams.set(paramName, paramValue);
        const newURL = currentURL.toString();
      
        window.history.pushState(null, null, newURL);
      };
      
      addParamToURL('c', name);

      const clearParams = () => {
        const currentURL = new URL(window.location.href);
        const params = new URLSearchParams('');
        currentURL.search = params.toString();
        window.history.pushState(null, null, currentURL.toString());
      };

    const popRow = 
    {
     opacity: animate ? 1 : 0, // Fade in effect
     transform: animate ? 'translateY(0px)' : 'translateY(50px)', // Move up effect
     transition: 'opacity 1s ease, transform 1s ease', // Animation duration and easing
     backgroundImage: animate && `url(images/${course.Image}.jpg)`, 
     backgroundPosition:'top center', 
     backgroundSize: exercise=== -2 ? 'cover' : '100%', 
     backgroundRepeat:'no-repeat'
    };



  
   

    useEffect(()=> {

        fetch(`/calls/cdata.php?c=${name}&id=${userID}&auth=${jwtToken}`) 
        .then((response) => response.json())
        .then((data) => {
            setCourse(data[0]);

            setTimeout(()=> {
                setAnimate(true)
                  }, 1000)
            
            if (data[0].Theme[0] === 'Audio')
            {
                setAudio(true)
            }

            })

        
            // get the id of the course
            const fetchData = async (name, userID, jwtToken) => {
              try {
                  const response = await fetch(`/calls/cdata.php?cid=${name}&id=${userID}&auth=${jwtToken}`);
                  const data = await response.json();
                  await clickedCourse(userID, data);
              } catch (error) {
                  console.error('Error fetching data:', error);
              }
          };


              if (!hasRun) {
                fetchData(name, userID, jwtToken);
                // eslint-disable-next-line
                hasRun = true;
            }
          
    
        },[name, userID, jwtToken]);


        const clickedCourse = async (userID, cid) => {
          try {
              const response = await fetch(`/posts/post-start.php`, {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json',
                  },
                  body: JSON.stringify([parseInt(userID), parseInt(cid), name]),
              });
              const result = await response.text();
              console.log(result);
          } catch (error) {
              console.error('Error logging course:', error);
          }
      };
  
      let hasRun = false;
  


        useEffect(()=>{

            window.scrollTo(0, 0);
            setTip(false);

            if (exercise === 5 && stage === 2) // log course as finished
            {

              fetch(`/calls/cdata.php?cid=${name}&id=${userID}&auth=${jwtToken}`) 
              .then((response) => response.json())
              .then((data) => {
                  logCourse(userID, data);
                  })

                const logCourse = (userID, cid) => {
                fetch(`/posts/post-finish.php`, {
                  method:'POST',
                  headers: {
                      'Content-Type': 'application/json',
                  },
                  body: JSON.stringify([parseInt(userID), parseInt(cid), name]),
              })
              .then(response => response.text())
              .then(response => {
                  console.log(response);
              })
             }

            }


        },[exercise, stage, name, userID, jwtToken]);




    return (
        <>
        { exercise >= 0 & !audio ?
        <Typewriters exercise={exercise} setExercise={setExercise} setStage={setStage} />
        :
        null
        }

        <section className='course' style={popRow}>
            { animate && 
            <>
           
            { audio ? // for audio courses

            <Audio course={course} setExercise={setExercise} exercise={exercise} goHome={goHome} clearParams={clearParams} userID={userID} name={name} jwtToken={jwtToken} />

            : // not audio...
            <>
            { exercise === -2 ?
            <div className='title-screen' onClick={()=> setExercise(-1) }>
                <h1 className='course-title'>{ course.Title }</h1>
                <h3>Click to start</h3>
            </div>
            : exercise === -1 ? // standard introduction
            <div className='copy-wrap'>
                <div className='copy-stage'>
                    <h4>Welcome!</h4>
                      
                       <div className='icon-row'>
                           <div className='left'>
                             <PiNotebookDuotone size={60} fill={'#413d45'} />
                           </div>
                           <div className='right'>
                             <p>To experience the full benefits of our morning writing courses, simply grab a notebook and pen, find a quiet spot, and let yourself write freely and continuously. Allow a minimum of 30 minutes for each session.</p>
                           </div>
                        </div>

                        <div className='icon-row'>
                           <div className='left'>
                             <PiClockLight size={60} fill={'#413d45'} />
                           </div>
                           <div className='right'>
                           <p>Each session includes a warm up, followed by three exercises of five minutes and finishes with a 10-minute exercise. A timer will keep you on track on the top right-hand side of the page.</p>
                           </div>
                        </div>

                        <div className='icon-row'>
                           <div className='left'>
                             <PiCircleDuotone size={60} fill={'#413d45'} />
                           </div>
                           <div className='right'>
                           <p>The yellow circles (see one below!) will guide you through the session and track time, while a set of typewriters in the header will chart your progress through the course. Don't forget to click it after the final exercise so you can log it as completed!</p>
                           </div>
                        </div>


                        <div className='icon-row'>
                           <div className='left'>
                           <PiCubeDuotone size={60} fill={'#413d45'} />
                           </div>
                           <div className='right'>
                           <p>And if you need a small nudge or a place to start, click on the "Writer's Block" in the bottom right corner of the exercise!</p>
                           </div>
                        </div>

                    <div className='circle-start' onClick={()=> setExercise(0)}>Click to Start!</div>
                </div>

            </div>
            :
            <div className={exercise === 5 & stage === 2 ? 'copy-wrap orange-back' : 'copy-wrap'}>
                { stage === 1 ? // is the setup
                <div className='copy-stage'>
                <h4>{course.Content[exercise].Level}</h4>
                <div className='cstyle' dangerouslySetInnerHTML={{__html: sanitizer(course.Content[exercise].Intro)}} />   

                { exercise !== 5 ?

                  <div className='circle-start' onClick={()=> setStage(2)}>Start exercise</div>
                :
                 <div className='circle-start' onClick={()=> setStage(2)}>Click to finish</div>

                }
                </div>


                : // is the task
                <div className='copy-stage'>
                 { exercise !== 5 ?
                <h4>{course.Content[exercise].Level}</h4>
                :
                <h4>Final thought</h4>
                 }
                {course.Content[exercise]?.Visual && // if has visual
                    <img src={`images/${course.Content[exercise].Visual}`} alt='exercise prompt' className='image-prompt' />
                }
                { exercise !== 5 ?
                <>
                    <Counter minutes={course.Content[exercise].Interval} setExercise={ setExercise } excercise ={ exercise } setStage={ setStage } />
                    <PiCubeDuotone size={40} fill={'#413d45'} className='b-right cursor' onClick={()=> setTip(true)} />
                </>
                :
                <div className='circle-start b-right' onClick={()=> {goHome(0); clearParams(); }}>Back home</div>
                }
                <div className='cstyle' dangerouslySetInnerHTML={{__html: sanitizer(course.Content[exercise].Task)}} />
                { tip && 
                <div className='tip' dangerouslySetInnerHTML={{__html: sanitizer(course.Content[exercise].Tip)}} />
                }
                </div>
                }
            </div>
            }
            </>
            }
            </>
            }
        </section>
        </>
    )
}

export default Course