import { useState, useEffect } from 'react'
import dompurify from 'dompurify'


const Featured = ({ value, id, setCourse, jwtToken }) => {
    
    
    const sanitizer = dompurify.sanitize;
    const [box, setBox] = useState();
    const [animate, setAnimate] = useState(false);


    useEffect(()=> {

            fetch(`/calls/cdata.php?f=${value}&id=${id}&auth=${jwtToken}`) 
            .then((response) => response.json())
            .then((data) => {
                setBox(data);
                      })
        
         
       setTimeout(()=> {
          setAnimate(true)
            }, 1000)
 

      },[value, id, jwtToken]);


      const popRow = 
      {
       opacity: animate ? 1 : 0, // Fade in effect
       transform: animate ? 'translateY(0px)' : 'translateY(50px)', // Move up effect
       transition: 'opacity 1s ease, transform 1s ease', // Animation duration and easing
      };



    return (
        <section className='featured' style={popRow}>
            { box &&
            <div className='featured-wrap' onClick={()=>setCourse( box[0].Title )}>
                <h2 className='tag'>Featured</h2>
                <div className='left cursor' style={{backgroundImage: `url(images/${box[0].Image}.jpg)`, backgroundPostion:'center, center', backgroundSize:'cover', backgroundRepeat:'no-repeat'}}>{box[0].Title}</div>
                <div className='right pstyle' dangerouslySetInnerHTML={{__html: sanitizer(box[0].Content[0].Intro)}} />  
            </div>
            }
        </section>
    )
}




export default Featured

