const SSdata= [
    {
        "Title": "Dark Neighbourhood",
        "Author": "Vanessa Onwuemezi",
        "Description": "A wonderful, surreal and uncanny book by a Write & Shine guest tutor.",
        "Link":"https://uk.bookshop.org/a/9346/9781913097707",
        "Image":"dark-neighbourhood.jpg"
    },
    {
        "Title": "The Collected Stories of Grace Paley",
        "Author": "Grace Paley",
        "Description": "A writer, teacher and peace activist, Paley's stories are filled with people, vivid scenes, and city life. Her work has a bright, fast energy.",
        "Link":"https://uk.bookshop.org/a/9346/9780349010618",
        "Image":"grace-paley.jpg"
    },
    {
        "Title": "Tell Me a Riddle, Requa I, and Other Works",
        "Author": "Tillie Olsen",
        "Description": "Olsen's story \"I Stand Here Ironing\" is a story of a mother reflecting on her teenage daughter's life in response to concerns from a teacher. The 'back and forth' motion of the iron is echoed in the shape of the piece, with memories, arguments and regrets rising and falling.",
        "Link":"https://uk.bookshop.org/a/9346/9780803245778",
        "Image":"tell-me-a-riddle.jpg"
    },
    {
        "Title": "In the Middle of the Fields",
        "Author": "Mary Lavin",
        "Description": "Lavin's stories are subtle. Populated by determined women in rural Ireland, trying to live on their own terms, alongside the loneliness and the judgements of others. She shows us how the quiet, intimate overlooked details of life can be tremendously important.",
        "Link":"https://uk.bookshop.org/a/9346/9781848405318",
        "Image":"in-the-middle-of-the-fields.jpg"
    },
    {
        "Title": "Birds of America",
        "Author": "Lorrie Moore",
        "Description": "Moore has such a wry, ironic, conversational voice. Those puns and quips! The way her characters stumble through life. So good.",
        "Link":"https://uk.bookshop.org/a/9346/9780571260867",
        "Image":"birds-of-america.jpg"
    },
    {
        "Title": "Selected Stories",
        "Author": "Katherine Mansfield",
        "Description": "In Mansfield's story 'Bliss,' the pure joy captured, and throughout the piece, is spellbinding, especially as later it's replaced with a darker set of feelings.",
        "Link":"https://uk.bookshop.org/a/9346/9780199537358",
        "Image":"katherine-mansfield.jpg"
    },
    {
        "Title": "The Dog of the Marriage",
        "Author": "Amy Hempel",
        "Description": "In Hempel's stories, each scene is distilled to its essence. Each sentence matters. Her lucid, lyrical writing sees into the depth of people's lives.",
        "Link":"https://uk.bookshop.org/a/9346/9781847247322",
        "Image":"the-dog-of-the-marriage.jpg"
    },
    {
        "Title": "The Collected Short Stories",
        "Author": "Jean Rhys",
        "Description": "Rhys' spare, terse language is a wonder. Each word chosen like it's a precious thing.",
        "Link":"https://uk.bookshop.org/a/9346/9780141984858",
        "Image":"jean-rhys.jpg"
    },
    {
        "Title": "The Lottery and Other Stories",
        "Author": "Shirley Jackson",
        "Description": "Jackson's work is always unsettling, with the edges of this world blurred with the supernatural.",
        "Link":"https://uk.bookshop.org/a/9346/9780141191430",
        "Image":"the-lottery.jpg"
    },
    {
        "Title": "The Bloody Chamber and Other Stories",
        "Author": "Angela Carter",
        "Description": "A classic collection of inverted fairy tales and filled with very visible, vocal, violent women.",
        "Link":"https://uk.bookshop.org/a/9346/9780099588115",
        "Image":"the-bloody-chamber.jpg"
    },
    {
        "Title": "Guestbook: Ghost Stories",
        "Author": "Leanne Shapton",
        "Description": "This is a book that \"to read out of the corner of your eye,\" says one critic. It's full of suggestions and impressions, whispers and glimpses.",
        "Link":"https://uk.bookshop.org/a/9346/9781846144936",
        "Image":"guest-book.jpg"
    },
    {
        "Title": "Sweet Home",
        "Author": "Wendy Erskine",
        "Description": "What connects each of the stories is a tremendous sense of place; it's a city book that captures extraordinary, ordinary people.",
        "Link":"https://uk.bookshop.org/a/9346/9781529017076",
        "Image":"sweet-home.jpg"
    },
    {
        "Title": "Suddenly, a Knock on the Door",
        "Author": "Etgar Keret",
        "Description": "Surreal and odd, brilliant and unexpected stories.",
        "Link":"https://uk.bookshop.org/a/9346/9780099563327",
        "Image":"suddenly-a-knock-on-the-door.jpg"
    },
    {
        "Title": "Nudibranch",
        "Author": "Irenosen Okojie",
        "Description": "A wonderful collection of magic realist stories.",
        "Link":"https://uk.bookshop.org/a/9346/9780349700915",
        "Image":"nudibranch.jpg"
    },
    {
        "Title": "Paradise Block",
        "Author": "Alice Ash",
        "Description": "Ash's linked stories set in the same location—Paradise Block—is tense, tender, bright and heartbreaking. The opening story 'Eggs' is about a daughter & mother exchanging roles and eventually their entire physical selves.",
        "Link":"https://uk.bookshop.org/a/9346/9781788165556",
        "Image":"paradise-block.jpg"
    },
    {
        "Title": "Her Body And Other Parties",
        "Author": "Carmen Maria Machado",
        "Description": "Her Body and other Parties is a fascinating short fiction collection: each is a mix of fairytale, science fiction, horror and ghost story. Each tale tells of women and their bodies—pleasure, senses, seduction—the way it tells the truth even when words can't.",
        "Link":"https://uk.bookshop.org/a/9346/9781781259535",
        "Image":"her-body.jpg"
    }
]

export default SSdata