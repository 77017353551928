import { useState, useEffect } from 'react'
import dompurify from 'dompurify'
import { MdRefresh } from 'react-icons/md'


const Random = ({ setCourse, id, jwtToken }) => {
    
    
    const sanitizer = dompurify.sanitize;
    const [box, setBox] = useState();
    const [animate, setAnimate] = useState(false);
    const [run, setRun] = useState(0);



    useEffect(()=> {

        const today = new Date();
        const minutes = today.getSeconds();

            fetch(`/calls/cdata.php?r=1&id=${id}&auth=${jwtToken}&x=${minutes}`, {cache: 'no-store'}) 
            .then((response) => response.json())
            .then((data) => {
                setBox(data);
                      })
        
         
       setTimeout(()=> {
          setAnimate(true)
            }, 1000)
 

      },[run, id, jwtToken]);


      const popRow = 
      {
       opacity: animate ? 1 : 0, // Fade in effect
       transform: animate ? 'translateY(0px)' : 'translateY(50px)', // Move up effect
       transition: 'opacity 1s ease, transform 1s ease', // Animation duration and easing
      };



    return (
        <section className='featured'  style={popRow}>
            { box &&
            <div className='featured-wrap'>
                <h2 className='tag'>Your random selection!</h2>
                
                <MdRefresh className='b-right' size={35} fill={'#51516b'} style={{cursor:'pointer'}} onClick={()=>setRun(run + 1)} />
                <div className='instruct b-right offset' onClick={()=>setRun(run + 1)}>Click for another!</div>

                <div className='left yellow-back black-font cursor' onClick={()=>setCourse( box[0].Title )}>{box[0].Title}
                <div className='instruct'>Click to start</div>
                </div>
                <div className='right pstyle' dangerouslySetInnerHTML={{__html: sanitizer(box[0].Content[5].Task)}} /> 
            </div>
            }
        </section>
    )
}




export default Random

