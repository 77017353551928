import { useState, useEffect, useRef } from 'react'


function Counter({ minutes, setExercise, exercise, setStage }) {
    const [timeRemaining, setTimeRemaining] = useState(minutes * 60);
    const audioRef = useRef();
    const [skip, setSkip] = useState(false);

  
    useEffect(() => {
      const interval = setInterval(() => {
        if (timeRemaining > 0) {
          setTimeRemaining(prevTime => prevTime - 1);
        }
        else {
          clearInterval(interval);
          setCountdownComplete(true);
          audioRef.current.play();
        }
      }, 1000);
  
      return () => clearInterval(interval);
    }, [timeRemaining, minutes]);
  
    const [countdownComplete, setCountdownComplete] = useState(false);


    const calculatePercentage = () => {
      return ((minutes * 60 - timeRemaining) / (minutes * 60)) * 100;
    };
  
    const displayedMinutes = Math.floor(timeRemaining / 60);
    const seconds = timeRemaining % 60;

    // to increment exercise by 1
    const incrementCount = () => {
      setExercise(prevExercise => prevExercise + 1);
    };
  
    return (
      <>
      <audio ref={audioRef} src='audio/bell.wav'></audio>
      <div className='circle-clock' onClick={()=>{incrementCount(); setStage(1)}} onMouseOver={()=>setSkip(true)} onMouseLeave={()=>setSkip(false)}>

        <div className='countdown'>
          {countdownComplete ?
          <>
          Next
          </>
          : skip ?
          <>
          Skip
          </>
          :
          <>
          {displayedMinutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </>
          }
        </div>
        <svg className='progress-ring' width='120' height='120'>
          <circle
            className='progress-ring-circle'
            stroke='#ffee78'
            strokeWidth='8'
            fill= {countdownComplete ? '#ffee78' : '#ffd462'}
            r='52'
            cx='60'
            cy='60'
            style={{
              strokeDasharray: '327',
              strokeDashoffset: `${326 - (326 * calculatePercentage()) / 100}`,
            }}
          />
        </svg>
      </div>
      </>
    );
  }

export default Counter