import { MdCancel } from 'react-icons/md'
import { useState } from 'react'


const Cancel = ({ id }) => {

    const [cancel, setCancel] = useState(false);

 

    return (
        <>
        <div className='cancel-wrap' id='cancel' onClick={()=>{  setCancel(!cancel); }}>
            <MdCancel size={40} fill={'#fff'} />
            <p className='white'>Click to cancel your subscription.</p>
         </div>
           {
            cancel &&
         <div className='cancel-msg'>
           <p className='coda'>We're sorry to see you go! Click the button below and you'll be taken to our payment provider's (Stripe) portal to complete your request.</p>
           <a href='https://billing.stripe.com/p/login/28o7uYa5P4LB5KU7ss'><button className='cta'>Cancel</button></a>
         </div>
         }
         </>
    )
}

export default Cancel