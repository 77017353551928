import { useState, useEffect } from 'react'
import { MdMoreVert, MdPlayArrow, MdBookmarkBorder, MdBookmark, MdClose } from 'react-icons/md'
import dompurify from 'dompurify'


const RowSearch = ({ results, animate, id, setCourse, jwtToken  }) => {

    const isMobile = window.innerWidth <= 768;
    const sanitizer = dompurify.sanitize;
    
    const [options, setOptions] = useState(false);
    const [select, setSelect] = useState([]);
    const [aniTitle, setAniTitle] = useState(false);

    const [buttonStack, setButtonStack] = useState(false);



      const popRow = 
      {
       opacity: animate ? 1 : 0, // Fade in effect
       transform: animate ? 'translateY(0px)' : 'translateY(50px)', // Move up effect
       transition: 'opacity 1s ease, transform 1s ease', // Animation duration and easing
      };

      const popTitle = 
      {
       opacity: aniTitle ? 1 : 0, // Fade in effect
       transform: aniTitle ? 'translateY(0px)' : 'translateY(50px)', // Move up effect
       transition: 'opacity 1s ease, transform 1s ease', // Animation duration and easing
      };


      const editStack = (type, userID, cat, title) => {

        // get id of course
        fetch(`/calls/cdata.php?cid=${title}&id=${userID}&auth=${jwtToken}`) 
        .then((response) => response.json())
        .then((cid) => {
            
          fetch(`/posts/post-stack.php`, {
            method:'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify([type, parseInt(userID), cat, parseInt(cid), title]),
             })
            .then(response => response.text())
            .then(response => {
                console.log(response);
                if (type === 'add')
                  {
                   setButtonStack(true);
                  }
                else
                {
                  setButtonStack(false);
                }
            })

        })

      }


      const runPreview = (title) => {

            fetch(`/calls/cdata.php?f=${title}&id=${id}&auth=${jwtToken}`) 
            .then((response) => response.json())
            .then((data) => {
                setSelect(data);
                    })

          setOptions(true);


         // check if in stack

         fetch(`/posts/post-stack.php`, {
          method:'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(['check', parseInt(id), null, null, title]),
           })
          .then(response => response.text())
          .then(response => {
            if (response.trim() === 'false') {  setButtonStack(false) }
            else { setButtonStack(true) }
              console.log(response);
          })

         setTimeout(()=> {
            setAniTitle(true);
              }, 100)
      }

      const optionReset = () =>
        {
          setOptions(false);
          setAniTitle(false);
          setButtonStack(false);
        }


    return (
        <>
        { options && select.length > 0 &&
        <div className='opt-box-container'>
            <div className='opt-box'>
                <div className='close' onClick={()=> optionReset()}><MdClose size={50} fill={'#413d45'} /></div>
              <h4 style={popTitle}>{select[0].Title}</h4>
             
              <div className='opt-row' style={popTitle}>
                 
                 <button className='start'  
                   onClick={()=>setCourse( select[0].Title )}>Start <MdPlayArrow size={30}/>
                 </button>
                 

            { buttonStack ? 
              <button className='start' style={{
                color:'#413d45',
                backgroundColor: '#ffee78'
              }} 
                onClick={() => editStack('remove', id, 'Course', select[0].Title)}>
                Stacked <MdBookmark size={30} fill={'#ffd462'}/>
              </button>

                :

              <button className='stack'
                onClick={() => editStack('add', id, 'Course', select[0].Title)}>
                Stack <MdBookmarkBorder size={30}/>
              </button>

                }

              </div>


              <div className='pstyle' style={popTitle} dangerouslySetInnerHTML={{__html: sanitizer(select[0].Content[0].Intro)}} />  
            </div>
        </div>
        }
        <section className='row' style={popRow}>
            { results &&
               <div className='row-wrap'>
                {
                   (
                    results.map((d,i)=>       
                     <div className='card' key={i} style={isMobile ? {backgroundImage: `url(images/${d.Image}-mob.jpg)`, backgroundPostion:'center, center', backgroundSize:'cover', backgroundRepeat:'no-repeat'}
                    :
                    {backgroundImage: `url(images/${d.Image}.jpg)`, backgroundPostion:'center, center', backgroundSize:'cover', backgroundRepeat:'no-repeat'}
                     
                     } onClick={()=>setCourse( d.Title )}>
                       
                        <div className='more'  onClick={(e)=>{ e.stopPropagation(); runPreview(d.Title) }}>
                          <MdMoreVert size={30} fill={'#fff'} />
                        </div>
                        <span>{d.Title}</span>
                     
                     </div>
                     
                    )
                    )                    
                } 
                </div>

            }
                
         </section>
            </>
    )
}

export default RowSearch


