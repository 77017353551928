import './App.css';
import Header from './components/Header'
import HeaderBlank from './components/HeaderBlank'
import Home from './pages/Home'
import Course from './pages/Course'
import OnWriting from './pages/OnWriting'
import Stack from './pages/Stack'
import Account from './pages/Account'
import Login from './pages/Login'
import Footer from './components/Footer'


import { useEffect, useState } from 'react'

function App() {

  const [load, setLoad] = useState(false);

  // setting pages
  const [course, setCourse] = useState(0);
  const [article, setArticle] = useState(null);
  const [onwriting, setOnwriting] = useState(false);
  const [account, setAccount] = useState(false);
  const [stack, setStack] = useState(false);

  // setting login
  const jwtToken = localStorage.getItem('wsjwt');
  const [logged, setLogged] = useState(false);
  const [userID, setUserID] = useState(null);

  // params
  const currentURL = new URL(window.location.href);
  const params = new URLSearchParams(currentURL.search);
  const paramCValue = params.get('c');
  const paramAValue = params.get('a');

  const expired = (unix) => {
    const date = new Date(unix * 1000);
  
    if (date > Date.now()) {
      return false; // The date has not passed
    } else {
      return true; // The date has passed
    }
  }

  const [fadeInFooter, setFadeInFooter] = useState(false);

  useEffect(()=>{

    if (jwtToken !== null && jwtToken !== 'undefined')  {

      fetch('/auth/check.php', {
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ jwtToken }),
    })
    .then(response => response.json())
    .then(response => {
      if (response.message === 'Valid' & !expired(response.expiry))
      {
        // set userID at the top level
        setLogged(true);
        setUserID(response.userID);
        setLoad(true);
      }
      else
      {
        setLogged(false);
        setLoad(true);
      }
    })
    .catch(error => {
        console.log(error);
    });

        }
  
      else {
      setLogged(false);
      setLoad(true);
    }

  

    if ( paramCValue !== null )
    {
      setCourse(paramCValue);
    }

    if ( paramAValue !== null )
    {
      setArticle(paramAValue);
    }


    setTimeout(()=> {
      setFadeInFooter(true)
    }, 3000)
    
  },[paramCValue, paramAValue, logged, jwtToken]);



  return (
    <>
     { !load ?
      <div className='loader'></div>
     :
      <>
      { !logged ? // user needs to log in
        <>
        <HeaderBlank />
        <Login setLogged={ setLogged } setUserID={ setUserID } />
        </>
        
        :

        <>
        <Header course={ course } setCourse={ setCourse } onwriting={ onwriting } setOnwriting={ setOnwriting } account={ account } setAccount={ setAccount } setLogged={ setLogged } userID={ userID } stack={ stack } setStack={ setStack } />
        {course === 0 || course === -1 ?
        <>
        {
        <>
          { // article ?
        //  <OnWriting article={ article } setArticle={ setArticle } userID={ userID} />
          // : 
          stack ? 
          <Stack userID={ userID }  jwtToken={  jwtToken } setCourse={ setCourse } />
          :
          onwriting ?
          <OnWriting article={ article } setArticle={ setArticle } userID={ userID} />
          : account ?
          <Account userID={ userID} />
          :
          <>
          <Home setCourse={ setCourse } userID={ userID} />
          { fadeInFooter &&
          <Footer setAccount={ setAccount } setOnwriting={ setOnwriting } />
          }
          </>
          }
        </>
        }
        </>
        :
          <Course name={ course } goHome={ setCourse } userID={ userID } />
        }
      </>
      }
      </>
    }
   </>
  );
}

export default App;
