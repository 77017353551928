import {useState, useEffect} from 'react'


const Progress = ({ id }) => {

    const [data, setData] = useState([]);
    const [empty, setEmpty] = useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(()=> {

        fetch('/calls/user-progress.php', {
            method:'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(id),
        })
        .then(response => response.json())
        .then(response => {

            if (response.length > 0)
            {
                response.reverse();
            
                response.forEach(a=>{ 
                   a.YearMonth = new Date(a.YearMonth).toLocaleDateString('en-GB', { year: 'numeric', month: 'long' });
                })
    
               setData(response);

            }
            else
            {
                setEmpty(true);
            }

            setLoaded(true);
            
        })
        .catch(error => {
            console.log(error);
        });

    },[id])

    return (
        <>
        { loaded &&
        
        <div className='details-wrap'>

        { !empty ?
    <>
        { data.map((d,i)=>(
                <div className='row' key={i}>
                    <div className='left'>
                        <p>{d.YearMonth}</p>
                    </div>
                    
                    <div className='right-prog'>
                        <div className='prog-bar' style={{width: `${d.TotalHours*30}px`}}></div>
                    {i === 0 ?
                    <p>{d.TotalHours} hour(s)</p>
                    :
                    <p>{d.TotalHours}</p>
                   }
                    </div>
                </div>
         ))
            }
        </>
            : 
            <div className='row'>
                <div className='left'>
                    <p>You're just getting started!</p>
                </div>
            </div>

        }

         </div>
           
     
        }
    </>
    )
}

export default Progress