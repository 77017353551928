import dompurify from 'dompurify'
import { MdBackspace } from 'react-icons/md';


const Article = ({pop, d, setPage, setArticle}) => {

    const sanitizer = dompurify.sanitize;

    const clearParams = () => {
      const currentURL = new URL(window.location.href);
      const params = new URLSearchParams('');
      currentURL.search = params.toString();
      window.history.pushState(null, null, currentURL.toString());
    };

    return (
        <>
        <div className='ow-article-back'></div>
        <MdBackspace size={40} fill={'#413d45'} className='fixed-left' onClick={()=>{setPage(false); clearParams(); setArticle(null)  }} />
        <div className='ow-article-wrap' style={pop}>
            <h2>{d.Title}</h2>
            <div className='lede-wrap'>
              <div className='circle' style={{backgroundImage: `url(images/${d.Image})`, backgroundPostion:'center, center', backgroundSize:'cover', backgroundRepeat:'no-repeat'}}></div>
              <p className='lede'>{d.Lede}</p>
          </div>
          <div className='nstyle' dangerouslySetInnerHTML={{__html: sanitizer(d.Copy)}} />
          <div className='fleuron'></div>

          {
            d.Course &&
            <>
            <p className='coda'>{ d.CTAcopy}</p>
            <a href={`/?c=${d.Course}`}><button className='cta'>Start '{d.Course}'</button></a>
            </>
          }  
          {
            d.Link &&
            <>
            <p className='coda'>{ d.CTAcopy}</p>
            <a href={`${d.Link}`} target='_blank' rel="noreferrer"><button className='cta'>Visit site</button></a>
            </>
          }  
            <div className='back-article-end' onClick={()=>{setPage(false); clearParams(); setArticle(null)  }}>
              <MdBackspace size={40} fill={'#ececec'} />
              <p>Close article</p>
          </div>
        </div>
        </>
    )
}

export default Article